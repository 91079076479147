import React from 'react'
import { NavLink } from 'react-router-dom'

const GrpPolicy = () => {
    return (
        <div className='bg-img'>
            <div className='col-md-9 m-5 p-5 d-flex'>
                <div>
                    <img src='/err.png' height="500px" />
                </div>
                <div className='m-5 mt-0 '>
                    <p className='revolution col-md-12 pb-2 mb-4 text-danger border-bottom border-danger'>Group Policy NOT Updated ???</p>
                    <div className='d-flex '>
                        <p className='fs-4 lead text-light lh-base col-md-9 '> <h2 ><i className='fw-bold text-light'> Security Vulnerabilities </i></h2> Security policies often include updates to address new threats or vulnerabilities. Failure to update these policies could leave systems or networks exposed to potential attacks.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Inconsistent Enforcement </i></h2>Inconsistent enforcement of policies across systems or departments can lead to confusion, inconsistency in security practices, and potential gaps in protection.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9'> <h2 ><i className='fw-bold text-light'>Compatibility Issues  </i></h2>New software or hardware deployments may require updates to group policies to ensure compatibility and proper functioning. Failure to update policies could lead to compatibility issues, system failures, or unexpected behavior.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Compliance Risks </i></h2> Many organizations are subject to regulatory requirements or industry standards that mandate certain security measures or data protection practices. Failure to update policies in line with these requirements could result in compliance violations and potential legal consequences.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9 text-justify'> <h2 ><i className='fw-bold text-light'>Operational Inefficiencies </i></h2>Updated policies often include optimizations or changes to improve operational efficiency. Failure to implement these updates could result in outdated processes, leading to inefficiencies or productivity losses.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>


                    </div>

                    <NavLink to="/windows" ><button type="submit" className="next p-3 mt-3 ms-5 text-dark rounded-pill col-md-3">Next </button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default GrpPolicy
