import React from 'react'
import Feature from './Feature'
import FAQ from './FAQ'
import ScheduleDemoButton from './ScheduleDemoButton'

const Home = () => {
    return (

        <div className="col-md-12">
            <header className="hero d-flex">
                <div className='align-item-center p-5 ms-5'>
                    <p className="revolution col-md-9">Revolutionize Your IT Desk with Automated Efficiency</p>
                    <p className='col-md-9 fst-italic fs-4 lh-base mt-5'>Introducing our cutting-edge automated solution designed to revolutionize IT service desk operations. By leveraging advanced technologies, we drastically reduce the workload of service desk personnel in IT companies. Our innovative solution streamlines processes, enhances efficiency, and ensures swift resolution of IT issues.</p></div>
                <div class="logo align-item-center ms-0">
                    <img src="/hero.png" height="500px" />
                </div>
            </header>
            <Feature />
            <FAQ />
            <ScheduleDemoButton />
        </div>

    )
}

export default Home
