import React from "react";
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">

            <div className="p-5 m-5 col-md-12">
                <div className="row d-flex">
                    <div className="col-lg-4 col-md-6 col-sm-7">
                        <div className="footer__about">
                            <div className="footer__logo">
                                <NavLink className="logo" to="/home"><img src="/databyte_logo-removebg-preview.png" height="90px" /></NavLink>
                            </div>
                            <p className="mt-4">Streamline your IT service desk with our automation solution. Cut down on queues and ticket backlogs, freeing up time for proactive problem-solving. Boost efficiency, reduce resolution times, and enhance user satisfaction. Embrace the future of IT management effortlessly.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-md-3 col-sm-8 ps-5 ms-5 mt-5">
                        <div className="footer__newsletter">
                            <h6>NEWSLETTER</h6>
                            <p>Subscribe for tech insights! Stay updated with the latest trends and expert perspectives delivered to your inbox.</p>
                            <form action="#" className="">
                                <input type="text" placeholder="Email" />
                                <button type="submit" className="submit-button p-2 mt-3 text-light rounded-pill col-md-6">Subscribe</button>
                            </form>
                        </div>
                    </div>

                    <div className="col-md-3 ps-5 ms-5 ">
                        <NavLink to="/contact"><button type="submit" className="contact p-2 mt-3 ms-5 text-dark rounded-pill col-md-6">Contact Us</button>
                        </NavLink>
                        <div className="footer__social mt-4">
                            <NavLink to="https://www.linkedin.com/company/data-byte-pvt-ltd/"><i className="fa fa-facebook"></i></NavLink>
                            <NavLink to="https://www.linkedin.com/company/data-byte-pvt-ltd/"><i className="fa fa-twitter"></i></NavLink>
                            <NavLink to="https://www.linkedin.com/company/data-byte-pvt-ltd/"><i className="fa fa-youtube-play"></i></NavLink>
                            <NavLink to="https://www.linkedin.com/company/data-byte-pvt-ltd/"><i className="fa fa-instagram"></i></NavLink>
                            <NavLink to="https://www.linkedin.com/company/data-byte-pvt-ltd/"><i className="fa fa-linkedin"></i></NavLink>
                        </div>
                    </div>

                    <div className="d-flex mt-5">
                        <div className="col-lg-2 col-md-3 col-sm-5 mt-2 ps-0">
                            <div className="footer__widget">
                                <h6>Company</h6>
                                <ul className="list-unstyled ">
                                    <li><NavLink className="links text-decoration-none" to="/about">About</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="/needhelp">Help</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="/contact">Contact</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="/faq">FAQ</NavLink></li>
                                </ul>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-4 ps-0">
                            <div className="footer__widget">
                                <h6>Quick Links</h6>
                                <ul className="list-unstyled">
                                    <li><NavLink className="links text-decoration-none" to="/">Privacy Policy</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="#">Terms & Conditions</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="#">Articles</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="#"></NavLink></li>
                                </ul>
                            </div>
                        </div>


                        <div className="col-lg-2 col-md-3 col-sm-4">
                            <div className="footer__widget">
                                <h6>Expertises</h6>
                                <ul className="list-unstyled">
                                    <li><NavLink className="links text-decoration-none" to="/">Technology</NavLink></li>
                                    <li><NavLink className="links text-decoration-none" to="#">Solutions</NavLink></li>

                                </ul>
                            </div>
                        </div>

                    </div>

                </div>



                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer__copyright__text mt-4">
                            <p>Copyright © 2024 All rights reserved | This Page is made with <i className="fa fa-heart" aria-hidden="true"></i> by <NavLink className="text-decoration-none" to="https://databyte.in/" target="_blank">Databyte</NavLink></p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};
export default Footer;