import React from 'react'
import { NavLink } from 'react-router-dom'

const KBarticles = () => {
    return (
        <div className='m-5 p-5 ps-5'>
            <h1 className='ps-5'>Articles</h1>
            <p className='carrd col-md-3 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>   </p>
            <div className='d-flex'>
                <div className='cardd shadow-lg mb-5 bg-body rounded mx-auto  col-md-3 p-5'>
                    <NavLink to='/grppolicy' className="text-decoration-none">
                        <div className='mb-4'  >
                            <img src='/grp-update.png' height="200px" />
                        </div>
                        <p><em><b>Group Policy is Not Updated</b><br /> When Group Policy fails to update properly, several issues can arise, impacting security, performance, and manageability.<br /> <a href="/grppolicy">Read more</a></em></p>
                    </NavLink>
                </div>

                <div className='cardd shadow-lg mb-5 bg-body rounded mx-auto col-md-3 p-5'>
                    <NavLink to='/windows' className="text-decoration-none">
                        <div className='mb-4'  >
                            <img src='/win-removebg.png' height="200px" />
                        </div>
                        <p><em><b>Not Installed the Latest Windows Patches?</b><br />Failing to install the latest Windows patches can lead to various problems affecting security, performance, and stability. <br /><a href="/windows">Read more</a></em></p>
                    </NavLink>
                </div>

                <div className='cardd shadow-lg mb-5 bg-body rounded mx-auto col-md-3 p-5'>
                    <NavLink to='/temp' className="text-decoration-none">
                        <div className='mb-4'  >
                            <img src='/temp.png' height="200px" />
                        </div>
                        <p><em><b>Uncleared Temporary or Junk Files on Windows</b><br />Neglecting to clear temporary or junk files on a Windows system can lead to a range of issues <br /><a href="/temp">Read more</a></em></p>
                    </NavLink>


                </div>
            </div>


        </div >
    )
}

export default KBarticles
