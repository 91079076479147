import './App.css';
import { Routes, Route } from "react-router-dom";
import Modal from 'react-modal';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import KBarticles from './components/KBarticles';
import Feature from './components/Feature';
import FAQ from './components/FAQ';
import ScheduleDemoButton from './components/ScheduleDemoButton';
import Footer from './components/Footer';
import GrpPolicy from './components/GrpPolicy';
import Windows from './components/Windows';
import Temp from './components/Temp';

function App() {
  return (
    <div className="col-md-12">
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/kbarticles" element={<KBarticles />} />
        <Route path="/grppolicy" element={<GrpPolicy />} />
        <Route path="/windows" element={<Windows />} />
        <Route path="/temp" element={<Temp />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/scheduledemo" element={<ScheduleDemoButton />} />
      </Routes>

      <Footer />

    </div>
  );
}

export default App;
