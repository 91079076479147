import React from 'react'
import { NavLink } from 'react-router-dom'

const Temp = () => {
    return (
        <div className='bg-img'>
            <div className='col-md-9 m-5 p-5 d-flex'>
                <div>
                    <img src='/temp_rm.png' height="300px" />
                </div>
                <div className='m-5 mt-0 '>
                    <p className='revolution col-md-12 pb-2 mb-4 text-danger border-bottom border-danger'>Uncleared Temporary or Junk Files on Windows</p>
                    <div className='d-flex '>
                        <p className='fs-4 lead text-light lh-base col-md-9 '> <h2 ><i className='fw-bold text-light'>  Disk Space Waste</i></h2> Temporary files accumulate over time and can consume significant disk space. Failure to clear these files can lead to insufficient storage space, which may slow down the system and hinder its performance.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Performance Degradation</i></h2>  As temporary files accumulate, they can clutter the system and slow down file access and retrieval processes. This can result in slower system boot times, application launches, and overall system performance.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9'> <h2 ><i className='fw-bold text-light'>Risk of System Crashes </i></h2>Excessive accumulation of temporary files can lead to disk fragmentation, which may increase the likelihood of system crashes or errors. Fragmented files are scattered across the disk, making it harder for the system to access them efficiently.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Security Risks </i></h2> Temporary files may contain sensitive information, such as cached passwords, browsing history, or temporary copies of confidential documents. If not cleared regularly, these files could be accessed by unauthorized users, posing a security risk.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9 text-justify'> <h2 ><i className='fw-bold text-light'>Privacy Concerns </i></h2> Failure to clear temporary files regularly may compromise user privacy by leaving behind traces of their activities. This could include browsing history, search queries, or recently accessed documents, which could be accessed by others who gain access to the system.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>


                    </div>
                    <NavLink to="/windows" ><button type="submit" className="next p-3 mt-3 ms-5 text-dark rounded-pill col-md-3">Previous </button></NavLink>
                </div>
            </div>
        </div>
    )
}

export default Temp
