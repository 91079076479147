// frontend/src/components/FormComponent.js
import React, { useState } from 'react';

function FormComponent() {
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [dateTime, setDateTime] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('http://localhost:5000/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, company, dateTime }),
        });
        if (response.ok) {
            alert('Data submitted successfully!');
            setEmail('');
            setCompany('');
            setDateTime('');
        } else {
            alert('Failed to submit data');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Email ID:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Company Name:</label>
                <input
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Date and Time:</label>
                <input
                    type="datetime-local"
                    value={dateTime}
                    onChange={(e) => setDateTime(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Submit</button>
        </form>
    );
}

export default FormComponent;
