// ScheduleDemoButton.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import FormComponent from './FormComponent';

const ScheduleDemoButton = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Scheduling demo for:', { name, email, date });
        // Close the modal after submission
        closeModal();
    };

    return (
        <div className='col-md-12 text-center mb-5'>
            <h2>Schedule a Demo</h2>
            <button className='submit-button p-2 mt-3 text-light rounded-pill col-md-3' onClick={FormComponent}>Schedule Now</button>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="schedule col-md-6 bg-secondary-subtle p-5">
                <h2><em>Schedule a Demo</em></h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Date:</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className='d-flex p-4'>
                        <button className='p-3 bg-info bg-opacity border border-info rounded bg-success me-5' type="submit">Submit</button>
                        <button className='p-3 bg-info bg-opacity border border-danger rounded bg-danger' type="button" onClick={closeModal}>Cancel</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default ScheduleDemoButton;
