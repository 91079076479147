import React from 'react'
import { NavLink } from 'react-router-dom'

const Windows = () => {
    return (
        <div className='bg-img'>
            <div className='col-md-9 m-5 p-5 d-flex'>
                <div>
                    <img src='/windows-removebg.png' height="300px" />
                </div>
                <div className='m-5 mt-0 '>
                    <p className='revolution col-md-12 pb-2 mb-4 text-danger border-bottom border-danger'>Not Installed the Latest Windows Patches???</p>
                    <div className='d-flex '>
                        <p className='fs-4 lead text-light lh-base col-md-9 '> <h2 ><i className='fw-bold text-light'> Security Vulnerabilities </i></h2> Patches often address security vulnerabilities discovered in the operating system. Failure to install these updates leaves the system vulnerable to attacks, malware, and data breaches.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Performance Issues</i></h2>Some patches include performance improvements that can enhance the speed and efficiency of the system. Without these updates, users may experience slower performance or encounter glitches and bugs.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9'> <h2 ><i className='fw-bold text-light'>Compatibility Issues  </i></h2>New software releases and hardware updates may require specific patches to ensure compatibility with the Windows operating system. Without these patches, users may face compatibility issues, leading to software crashes or hardware malfunctions.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>
                        <p className='fs-4 lead text-light lh-base col-md-9 ms-5 ps-5'> <h2 ><i className='fw-bold text-light'>Non-compliance  </i></h2> In regulated industries, failing to install required patches may result in compliance violations. Regulatory bodies often mandate regular security updates to protect sensitive data and ensure the integrity of systems.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'>      </p></p>

                    </div>
                    <div className='d-flex'>
                        <p className='fs-4 lead text-light lh-base col-md-9 text-justify'> <h2 ><i className='fw-bold text-light'> Loss of Support</i></h2> Microsoft regularly releases patches to maintain and support its operating systems. Not installing these updates can lead to the loss of official support, leaving users without access to technical assistance and future updates.<p className=' col-md-6 text-center ps-5 ms-5 pb-2 mb-4 border-bottom border-danger  mt-3'></p></p>

                    </div>

                    <div className='d-flex col-md-12'>
                        <NavLink to="/grppolicy" ><button type="submit" className="next p-3 mt-3 ms-3 text-dark rounded-pill ">Previous</button>
                        </NavLink>
                        <NavLink to="/temp" ><button type="submit" className="next p-3 mt-3 ms-5 text-dark rounded-pill">Next </button>
                        </NavLink>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Windows
