import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar col-md-12 navbar-expand-lg navbar-light bg-light py-3 shadow-sm">
            <div className='container-fluid d-flex'>
                <div class="logo align-item-center ms-5">
                    <NavLink to="/"><img src="databyte_logo-removebg-preview.png" height="100px" /></NavLink>
                </div>
                <div className='nav'>
                    <ul className="nav-links mt-2">
                        <li className='fw-bold fs-5'><Link to="/">Home</Link></li>
                        <li className='fw-bold fs-5'><Link to="/feature">Features</Link></li>
                        <li className='fw-bold fs-5'><Link to="/kbarticles">KB Articles</Link></li>
                        <li className='fw-bold fs-5'><Link to="/about">About</Link></li>
                        <li className='fw-bold fs-5'><Link to="/contact">Contact</Link></li>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
