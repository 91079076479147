// src/components/FAQ.js
import React, { useState } from 'react';



const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const questions = [
        {
            question: "Can this software work on Linux, Mac, and Windows operating systems?",
            answer: "Yes, it can. The software is designed to be compatible with Linux, Mac, and Windows operating systems, ensuring flexibility and accessibility across different platforms."
        },
        {
            question: "Can I use this software offline?",
            answer: "Yes, the software can be used offline with limited functionality. Full functionality requires an internet connection."
        },
        {
            question: "How often is the software updated?",
            answer: "The software is updated regularly with new features, improvements, and security patches."
        },
        {
            question: "Does the software provide browser-based access and an admin portal for monitoring environments?",
            answer: "Yes, the software provides browser-based access and includes an admin portal for monitoring and managing environments."
        }
    ];

    const toggleAnswer = (index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="faq container col-md-9">
            <h2>Frequently Asked Questions</h2>
            <ul className="faq-list">
                {questions.map((item, index) => (
                    <li key={index} className="faq-item">
                        <div className="faq-question" onClick={() => toggleAnswer(index)}>
                            {item.question}
                            <span>{index === activeIndex ? '▲' : '▼'}</span>
                        </div>
                        {index === activeIndex && (
                            <div className="faq-answer">
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FAQ;
