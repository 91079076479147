import React from 'react'

const Feature = () => {
    return (
        <div className='col-md-12 p-5 mt-0'>

            <div className='col-md-12 d-flex'>
                <div className='col-md-6'>
                    <img src="/feature-1.png" height="550px" />
                </div>
                <div className='col-md-6 mt-5'>
                    <h1 >Reduce your ITSM tickets upto 40 to 50%</h1>
                    <p className="lead">Because now the end user will not raise a ticket, they will solve the issue on their own. <br />By using our proactive cobots and user initialted solutions.</p>

                    <ul>
                        <li className='features lead ps-3 bg-opacity-10 rounded col-md-6'><em>Active Directory (AD) Management</em></li>
                        <li className='features lead ps-3 bg-opacity-10 rounded col-md-6 mt-2'><em>Printer Support</em></li>
                        <li className='features lead ps-3 bg-opacity-10 rounded col-md-6 mt-2'><em>Software Installation and Configuration</em></li>
                        <li className='features lead ps-3 bg-opacity-10 rounded col-md-6 mt-2'><em>Security and Compliance</em></li>
                        <li className='features lead ps-3 bg-opacity-10 rounded col-md-6 mt-2'><em>Network Access and Connectivity</em></li>
                    </ul>
                    <p className='lead'>Deliver great service experiences fast - without the complexity of traditional ITSM solutions.</p>
                </div>
            </div>
            <div className="trusted-section">
                <div className="trusted-content col-md-6 ps-5 pt-5 mt-5">
                    <h3>Trusted Worldwide</h3>
                    <h2>Trusted by thousands of users and 100+ teams</h2>
                    <p className='lead'>
                        Our rigorous security and compliance standards are at the heart of all we do. We work tirelessly to protect you and your customers.
                    </p>
                    {/* <div className="trusted-links">
                        <a href="#legality-guide">Explore Legality Guide →</a>
                        <a href="#trust-center">Visit the Trust Center →</a>
    </div> */}
                </div>
                <div className="trusted-features pe-5 me-5">
                    <div className='d-flex m-3 pe-5'>
                        <div className="feature p-3 col-md-6 me-4">
                            <div className="icon">🔒</div>
                            <h4>500 Plus Solution</h4>
                            <p>For Windows, Mac and Linux, with zero downtime</p>
                        </div>
                        <div className="feature p-3 col-md-6">
                            <div className="icon">👥</div>
                            <h4>User Friendly</h4>
                            <p>Trusted by IT Admins and Security Engineer's</p>
                        </div>
                    </div>
                    <div className='d-flex m-3 mt-0 pe-5'>
                        <div className="feature p-3 col-md-6 me-4">
                            <div className="icon">🔧</div>
                            <h4>100+ Compliance Solutions</h4>
                            <p>Which works without any user interactions</p>
                        </div>
                        <div className="feature p-3 col-md-6">
                            <div className="icon">🛡️</div>
                            <h4>Early Detection and Remediation</h4>
                            <p>To reduce incidents</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature
